import { Component, BaseComponent, Prop, namespace } from '@zento-lib/components';
import { decodePhpHtmlString } from '@zento-lib/util/html';
import { AppContextStore, KEY as appKey } from 'theme/@types/zento/stores/applicationContext';
import type { VideoWidgetData } from 'theme/stores/cms/types';

import { Modal } from '../../../organism/Modal/Modal';
import { MainImage } from '../../../atom/MainImage/MainImage';
import { InnerHTML } from '../../../atom/InnerHTML';

import type { IVideoWidget } from './VideoWidget.d';
import style from './style.scss';

const appContextStore = namespace<AppContextStore>(appKey);

@Component({})
export class VideoWidget extends BaseComponent<IVideoWidget, unknown> {
  /**
   * An object containing component data
   */
  @Prop({ type: Object, required: true })
  videoWidgetData: VideoWidgetData;

  @appContextStore.Getter('isMobile')
  private isMobile: boolean;

  @appContextStore.Getter('isDesktop')
  private isDesktop: boolean;

  beforeMount() {
    this.showVideoModal = this.showVideoModal.bind(this);
  }

  mounted() {
    setTimeout(this.toggleMute, 30);
  }

  get videoUrl() {
    if (this.videoWidgetData.autoplay && this.videoWidgetData.video_url && this.videoWidgetData.video_url?.length) {
      return (
        this.extended.$config.zento.theme.storeData.storeUrl +
        (this.videoWidgetData.video_url.startsWith('/media/video')
          ? this.videoWidgetData.video_url
          : '/media/video' + this.videoWidgetData.video_url)
      );
    }

    return '';
  }

  render() {
    const imgConfig = this.extended.$config.zento.images.videoWidget;

    return (
      <section class={style.videoWidgetWrapper}>
        <div
          class={{
            [style.container]: true,
            [style.smallContainer]: this.videoWidgetData.width === 'small',
            [style.largeContainer]: this.videoWidgetData.width === 'large',
            [style.fullContainer]: this.videoWidgetData.width === 'full_width',
          }}>
          {this.videoWidgetData.heading ? (
            <h2
              class={{
                [style.title]: true,
                [style.centerTitle]: this.videoWidgetData.heading_position === 'center',
                [style.rightTitle]: this.videoWidgetData.heading_position === 'right',
              }}
              key='widget-title'>
              {this.videoWidgetData.heading}
            </h2>
          ) : null}

          {this.videoWidgetData.description ? (
            <InnerHTML
              contents={decodePhpHtmlString(this.videoWidgetData.description)}
              class={style.description}
              key='widget-description'
            />
          ) : null}

          {this.videoUrl ? (
            <video
              width='100%'
              height={this.isMobile ? imgConfig.height : 'auto'}
              id='widgetVideoId'
              name='media'
              controls
              autoplay
              muted
              loop>
              <source src={this.videoUrl} type='video/mp4' />
              <source src={this.videoUrl} type='video/ogg' />
              Your browser does not support HTML video.
            </video>
          ) : (
            <span onClick_capture={this.showVideoModal} class={style.widgetImgWrapper}>
              <MainImage
                image={{ src: this.videoWidgetData.preview_image, loading: this.videoWidgetData.preview_image }}
                width={imgConfig.width}
                height={imgConfig.height}
                tabletWidth={imgConfig.tabletWidth}
                tabletHeight={imgConfig.tabletHeight}
                desktopWidth={imgConfig.desktopWidth}
                desktopHeight={imgConfig.desktopHeight}
                folder=''
                resize='xc'
                class={style.widgetImg}
                key='widget-img'
              />
            </span>
          )}

          <Modal
            name={'modal-video-' + this.videoWidgetData.video_id}
            width={this.isDesktop ? imgConfig.desktopWidth : imgConfig.width}
            class={style.modalWrapper}>
            {this.videoWidgetData.source === 'youtube' ? (
              <iframe
                width='100%'
                height={this.isDesktop ? imgConfig.desktopHeight : '100%'}
                src={'https://www.youtube.com/embed/' + this.videoWidgetData.video_id + '?autoplay=1'}
                title={this.videoWidgetData.heading}
                frameborder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowfullscreen
                slot='content'
              />
            ) : (
              <iframe
                src={'https://player.vimeo.com/video/' + this.videoWidgetData.video_id + '?autoplay=1'}
                width='100%'
                height={this.isDesktop ? imgConfig.desktopHeight : '100%'}
                frameborder='0'
                allow='autoplay; fullscreen; picture-in-picture'
                allowfullscreen
                slot='content'
              />
            )}
          </Modal>
        </div>
      </section>
    );
  }

  /**
   * Handler for showing video modal
   */
  private showVideoModal() {
    this.broadcast('modal-show', 'modal-video-' + this.videoWidgetData.video_id);
  }

  private toggleMute() {
    const video = document.getElementById('widgetVideoId') as any;

    if (video) {
      video.muted = true;
      video.play();
    }
  }
}
